import logo from "./logo.svg";
import "./App.css";
import React from "react";

const fontFamily = "Bebas Neue";

function App() {
  return (
    <div
      className="App"
      style={{
        fontFamily: fontFamily,
        boxSizing: "border-box",
        width: "100vw",
        wordBreak: "break-word",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "100%"
      }}
    >
      {/* <div style={{display: "flex", flexDirection: "row"}}> */}
      <h1 style={{ verticalAlign: "middle", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>
        Greg Abbott is going to say this was unforeseeable.
      </h1>
      {/* </div> */}
      <h1 style={{ color: "#E25043", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>He reopened Texas on March 2nd, 2021.</h1>
      <h1 style={{ color: "#F4AC57", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>I bought this domain on March 3rd, 2021.</h1>
      <h1 style={{ color: "#F2D452", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>Greg Abbott is a dick.</h1>
      <h1 style={{ color: "#F99D90", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>Stay safe. Wear a mask. Get a vaccine.</h1>
      <h1 style={{ color: "#8BC8B8", fontSize: 200, minHeight: "100vh", margin: 0, maxWidth: "100vw" }}>Don't be a dick like Greg Abbott.</h1>
    </div>
  );
}

export default App;
